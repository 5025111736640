const Title = () => {
  const logo = "/textoriginal.png";
  return (
    <div className="div1">
      <img src={logo} style={{ width: "40%", height: "auto" }} />
    </div>
  );
};

export default Title;
